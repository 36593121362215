import http from "../http";
import BaseServiceHttp from "./BaseServiceHttp";
import qs from "qs";
class Jasper extends BaseServiceHttp {
  constructor(id) {
    const resource = "/relatorios";
    let relationship = {
      //Campanha
      campanha: "campanhas",
      metaCampanha: "meta-campanha",
      resumoCampanha: "resumo-campanha",
      resumoIndicador: "resumo-indicador",
      resumoGanhouNaoGanhou: "resumo-ganhou-nao-ganhou",
      //Comissao
      comissao: "comissoes",
      colaborador: "colaborador",
      comissaoEquipe: "comissao-equipe",
      comissaoGeral: "comissao-geral",
      comissaoGeralEscritorio: "comissao-geral-escritorio",
      comissaoGeralUnico: "comissao-geral-unico",
      entregaEquipe: "entrega-equipe",
      colaboradorEquipeResumo: "colaborador-equipe-resumo",
      inadiplenciaFinanceiraGrupo: "inadiplencia-financeira-grupo",
      funcaoMeta: "funcao-meta",
      mapaResumoEmpresa: "mapa-resumo-empresa",
      mapaResumoGeral: "mapa-resumo-geral",
      metaEmpresa: "meta-empresa",
      metaIndividual: "meta-individual",
      metaEquipe: "meta-equipe",
      monitorProposta: "monitor-proposta",
      notaFiscal: "nota-fiscal",
      notaFiscalSeguro: "nota-fiscal-seguro",
      notaFiscalVenda: "nota-fiscal-vendas",
      resumoEquipeAno: "resumo-equipe-ano",
      horaExtra: "hora-extra",
      notaFiscalServico: "nota-fiscal-servico",
      //Departamento Administrativo
      inadiplenciaFinanceira: "inadiplencia-financeira",
      comissaoExtrato: "comissao-extrato",
      naoPagamento: "nao-pagamento",
      comissaoDetalhado: "comissao-detalhado",
      //Departamento Comercial
      bonusVendedor: "bonus-vendedor",
      comissaoDepartamento: "comissao-departamento",
      comissaoProdutoForca: "comissao-produto-forca",
      detalhadoBonus: "detalhado-bonus",
      detalhadoConsorcio: "detalhado-consorcio",
      detalhadoSupervisorEquipe: "detalhado-supervisor-equipe",
      resumoVendedor: "resumo-vendedor",
      veiculoDetalhado: "veiculo-detalhado",
      veiculoDetalhadoCurto: "veiculo-detalhado-curto",
      veiculoDevolucao: "veiculo-devolucao",

      //Supervisor/Equipe
      comissaoSupervisorGeral: "comissao-supervisor-geral",
      colaboradorHistorico: "colaborador-historico",
      equipeColaborador: "equipe-colaborador",
      inadiplenciaCotaEquipe: "inadiplencia-cota-equipe",
      realizadoEmpresa: "realizado-empresa",
      realizadoEquipe: "realizado-equipe",
      realizadoIndividual: "realizado-individual",
      resumoEquipe: "resumo-equipe",
      veiculoEntrega: "veiculo-entrega"
    };
    super(id, resource, relationship);
  }

  async show(data) {
    let config = {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf"
      },
      params: data,
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    };
    try {
      return await http.get(this.getURI(), config);
    } catch (error) {
      let dataMessage = JSON.parse(await error.response.data.text());
      this.error("gerar", dataMessage.message);
    }
  }
}

export default id => new Jasper(id);
